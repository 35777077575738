.black            { color: $black; }
.white            { color: $white; }
.orange           { color: $orange; }
.orange-hover     { color: $orange-hover; }
.brown            { color: $brown; }
.dark             { color: $dark; }
.khaki            { color: $khaki; }
.cream            { color: $cream; }
.error            { color: $error; }
.grey             { color: $grey; }
.grey-light       { color: $grey-light; }