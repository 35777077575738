h1 {
    font-size: $size_46;
    font-family: JuliettaMessie;
}
  
h2 {
    font-size: $size_30;
    font-family: JuliettaMessie;
}
  
h3 {
    font-size: $size_48;
}
  
h4 {
    font-size: $size_34;
}
  
h5 {
    font-size: $size_24;
}
  
h6 {
    font-size: $size_20;
}

h3, h4, h5, h6 {
    font-family: $main-font;
    font-weight: 500;
    font-style: normal;
}

.gibson {
    font-size: $size_14;
    &,  &-1, &-2, &-3 {
        font-family: $main-font;
        font-style: normal;
    }
    &-1 { font-weight: 200; }
    &-2 { font-weight: 500; }
    &-3 { font-weight: 400; }
    
}

.subtitle {
    font-size: $size_20;
    font-family: fertigo-pro, serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: $fertigo-spacing;
}