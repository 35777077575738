@import '@styles/variables.scss';
@import '@styles/mixins.scss';

.navbar {
    background: $brown;
    display: flex;
    height: $nav-height;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
    justify-content: center;

    @include mobile-screen-dynamic($nav-mobile-break) {       
        ul {
            display: none;
        }
    }
    
    @include desktop-screen-dynamic($nav-mobile-break) {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            display: flex;
            flex-basis: 100%;
            justify-content: space-evenly;
            align-items: center;
            height: $nav-height;

            font-size: $size_16;
            font-family: fertigo-pro, serif;
            font-weight: 400;
            font-style: normal;
            letter-spacing: $fertigo-spacing;

            li {
                display: block;
                position: relative;
                float: left;
                padding: 0 0.4em 0 0.4em;

                a {
                    display: block;
                    text-decoration: none;
                    white-space: nowrap;
                    color: $white;
                    &:hover {
                        color: $orange;
                    }
                }
            }
        }
    }

    .burger {
        position: fixed;
        left: 1rem;
        display: flex;
        height: $nav-height;

        button {
            font-size: 32px;
        }

        &Align:before {
            padding-top: 10px;
        }
    }

    .logo {
        margin-top: 12px;
        margin-left: 24px;

        @include mobile-screen-dynamic($nav-tiny-mobile-break) {
            display: none;
        }
    }

    .booknow {
        position: fixed;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 140px;
        height: $nav-height;
        margin-right: 1em;
        height: $nav-height;

        font-family: $main-font;
        font-weight: 400;
        font-style: normal;
        font-size: $size_16;
        color: white;
        margin-top: 1px;

        button {
            font-size: 16px;
        }
    }

    .phone {
        padding-bottom: 3px !important;
    }

    .icon-btn {
        background-color: Transparent;
        border: 0;
        padding: 0;
        color: $white;
        cursor: pointer;
    }

    .navitems {
        //width: 40%
    }


}
.contactcontainer {
    position: fixed;
    top: $nav-height;
    width: 100%;
    z-index: 10;
    background-color: $khaki;

    text-align: right;
    padding-right: 1em;

    font-family: $main-font;
    font-weight: 300;
    font-style: normal;
    font-size: $size_14;
    line-height: 0.8em;
}

.contactcontainershow {
    display: block;
}

.contactcontainerhide {
    display: none;
}

.spanLine {
    color: $khaki;
}