@import '@styles/variables.scss';
@import '@styles/mixins.scss';


$transactionTime :.4s;

.contactcontainer {
    position: fixed;
    top: $nav-height;
    width: auto;
    z-index: 10;
    background-color: $khaki;
    right: 0;
    text-align: right;
    padding-right: 1em;
    padding-left: 1em;
    font-family: $main-font;
    font-weight: 300;
    font-style: normal;
    font-size: $size_14;
    line-height: 1em;


    @include desktop-screen() {
        width: auto;
    }

    @include mobile-screen() {
        width: 100%;
    }
    overflow: hidden;
    height: 0px;


}

.contactcontainershow {
    overflow: hidden;
    height: 210px;
    animation: animateDown $transactionTime linear;

    .contactTitle {
        text-align: center;
    }


    hr {
        margin-left: 10px;
        height: 1px;
    border: none;
    background-color: #909090;

    }


}


@keyframes animateDown {
    from {
        height: 0px;
    }

    to {
        height: 210px;
    }
}

.contactcontainerhide {
    overflow: hidden;
    height: 0px;
    hr {
        margin-left: 10px;
        height: 1px;
    border: none;
    background-color: #909090;

    }
}

