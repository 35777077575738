$bootstrap-icons-font: "bootstrap-icons" !default;
$bootstrap-icons-font-src: url("/webFonts/icons/bootstrap-icons.woff2") format("woff2"),
url("/webFonts/icons/bootstrap-icons.woff") format("woff") !default;

@font-face {
  font-family: $bootstrap-icons-font;
  src: $bootstrap-icons-font-src;
  font-display: swap;
}

.bi::before,
.bi [class^="bi_"]::before,
.bi [class*=" bi_"]::before {
  display: inline-block;
  font-family: $bootstrap-icons-font !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$bootstrap-icons-map: (
  "123": "\f67f",
  "clock-fill": "\f101",
  "cloud-fill": "\f102",
  "cloud-rain-fill": "\f103",
  "cloud-sun-fill": "\f104",
  "envelope-fill": "\f105",
  "geo-alt-fill": "\f106",
  "info-circle-fill": "\f107",
  "lightning-charge-fill": "\f108",
  "moon-stars-fill": "\f109",
  "people-fill": "\f10a",
  "sun-fill": "\f10b",
  "telephone-fill": "\f10c",
  "caret-down-fill": "\f10d",
  "caret-left-fill": "\f10e",
  "caret-right-fill": "\f10f",
  "caret-up-fill": "\f110",
  "list-check": "\f111",
  "three-dots-vertical": "\f112",
  "three-dots": "\f113",
  "x-circle-fill": "\f114",
  "arrow-left": "\f115",
  "fullscreen-exit": "\f116",
  "fullscreen": "\f117",
  "grid-3x3-gap-fill": "\f118",
  "list": "\f119",
  "x-lg": "\f11a",
  "x": "\f11b",
  "arrow-down": "\f11c",
  "arrow-right": "\f11d",
  "arrow-up": "\f11e",
  "carret-down-circle-fill": "\f11f",
  "check-square-empty": "\f120",
  "check-square-fill": "\f121",
  "check-square": "\f122",
  "share-fill": "\f123",
  "star-fill": "\f124",
  "star-half": "\f125",
  "star": "\f126",
  "check-circle-fill": "\f127",
  "check-circle": "\f128",
  "check-square-minus": "\f129",
  "general-clock-fill": "\f12a",
  "general-envelope-fill": "\f12b",
  "general-geo-alt-fill": "\f12c",
  "general-info-circle-fill": "\f12d",
  "general-people-fill": "\f12e",
  "general-telephone-fill": "\f12f",
  "nav-arrow-down": "\f130",
  "nav-arrow-left": "\f131",
  "nav-arrow-right": "\f132",
  "nav-arrow-up": "\f133",
  "nav-caret-down-fill": "\f134",
  "nav-caret-left-fill": "\f135",
  "nav-caret-right-fill": "\f136",
  "nav-caret-up-fill": "\f137",
  "nav-carret-down-circle-fill": "\f138",
  "nav-fullscreen-exit": "\f139",
  "nav-fullscreen": "\f13a",
  "nav-grid-fill": "\f13b",
  "nav-list": "\f13c",
  "nav-three-dots-vertical": "\f13d",
  "nav-three-dots": "\f13e",
  "nav-x-circle-fill": "\f13f",
  "nav-x-lg": "\f140",
  "nav-x": "\f141",
  "toggle-check-circle-fill": "\f142",
  "toggle-check-circle": "\f143",
  "toggle-check-square-fill": "\f144",
  "toggle-check-square-minus": "\f145",
  "toggle-check-square": "\f146",
  "toggle-share-fill": "\f147",
  "toggle-star-fill": "\f148",
  "toggle-star-half": "\f149",
  "toggle-star": "\f14a",
  "weather-cloud-fill": "\f14b",
  "weather-cloud-rain-fill": "\f14c",
  "weather-cloud-sun-fill": "\f14d",
  "weather-lightning-charge-fill": "\f14e",
  "weather-moon-stars-fill": "\f14f",
  "weather-sun-fill": "\f150",
  "action-bookmark-fill": "\f151",
  "action-bookmark": "\f152",
  "action-equal": "\f153",
  "action-gear": "\f154",
  "action-heart": "\f155",
  "action-refresh": "\f156",
  "action-search": "\f157",
  "action-tick-circle-fill": "\f158",
  "action-tick-circle": "\f159",
  "action-tick": "\f15a",
  "action-view": "\f15b",
  "action-wrench": "\f15c",
  "nav-caret-down": "\f15d",
  "nav-caret-left": "\f15e",
  "nav-caret-right": "\f15f",
  "nav-caret-up": "\f160",
  "nav-carret-down-circle": "\f161",
  "nav-grid": "\f162",
  "toggle-share": "\f163",
  "alert-bell": "\f164",
  "alert-info-fill": "\f165",
  "alert-info": "\f166",
  "edit-duplicate": "\f167",
  "edit-edit": "\f168",
  "edit-link": "\f169",
  "edit-minus": "\f16a",
  "edit-plus": "\f16b",
  "edit-sort": "\f16c",
  "social-fb": "\f16d",
  "social-in": "\f16e",
  "social-tw": "\f16f",
  "social-yt": "\f170",
);

.bi-123::before { content: map-get($bootstrap-icons-map, "123"); }
.bi-clock-fill::before { content: map-get($bootstrap-icons-map, "clock-fill"); }
.bi-cloud-fill::before { content: map-get($bootstrap-icons-map, "cloud-fill"); }
.bi-cloud-rain-fill::before { content: map-get($bootstrap-icons-map, "cloud-rain-fill"); }
.bi-cloud-sun-fill::before { content: map-get($bootstrap-icons-map, "cloud-sun-fill"); }
.bi-envelope-fill::before { content: map-get($bootstrap-icons-map, "envelope-fill"); }
.bi-geo-alt-fill::before { content: map-get($bootstrap-icons-map, "geo-alt-fill"); }
.bi-info-circle-fill::before { content: map-get($bootstrap-icons-map, "info-circle-fill"); }
.bi-lightning-charge-fill::before { content: map-get($bootstrap-icons-map, "lightning-charge-fill"); }
.bi-moon-stars-fill::before { content: map-get($bootstrap-icons-map, "moon-stars-fill"); }
.bi-people-fill::before { content: map-get($bootstrap-icons-map, "people-fill"); }
.bi-sun-fill::before { content: map-get($bootstrap-icons-map, "sun-fill"); }
.bi-telephone-fill::before { content: map-get($bootstrap-icons-map, "telephone-fill"); }
.bi-caret-down-fill::before { content: map-get($bootstrap-icons-map, "caret-down-fill"); }
.bi-caret-left-fill::before { content: map-get($bootstrap-icons-map, "caret-left-fill"); }
.bi-caret-right-fill::before { content: map-get($bootstrap-icons-map, "caret-right-fill"); }
.bi-caret-up-fill::before { content: map-get($bootstrap-icons-map, "caret-up-fill"); }
.bi-list-check::before { content: map-get($bootstrap-icons-map, "list-check"); }
.bi-three-dots-vertical::before { content: map-get($bootstrap-icons-map, "three-dots-vertical"); }
.bi-three-dots::before { content: map-get($bootstrap-icons-map, "three-dots"); }
.bi-x-circle-fill::before { content: map-get($bootstrap-icons-map, "x-circle-fill"); }
.bi-arrow-left::before { content: map-get($bootstrap-icons-map, "arrow-left"); }
.bi-fullscreen-exit::before { content: map-get($bootstrap-icons-map, "fullscreen-exit"); }
.bi-fullscreen::before { content: map-get($bootstrap-icons-map, "fullscreen"); }
.bi-grid-3x3-gap-fill::before { content: map-get($bootstrap-icons-map, "grid-3x3-gap-fill"); }
.bi-list::before { content: map-get($bootstrap-icons-map, "list"); }
.bi-x-lg::before { content: map-get($bootstrap-icons-map, "x-lg"); }
.bi-x::before { content: map-get($bootstrap-icons-map, "x"); }
.bi-arrow-down::before { content: map-get($bootstrap-icons-map, "arrow-down"); }
.bi-arrow-right::before { content: map-get($bootstrap-icons-map, "arrow-right"); }
.bi-arrow-up::before { content: map-get($bootstrap-icons-map, "arrow-up"); }
.bi-carret-down-circle-fill::before { content: map-get($bootstrap-icons-map, "carret-down-circle-fill"); }
.bi-check-square-empty::before { content: map-get($bootstrap-icons-map, "check-square-empty"); }
.bi-check-square-fill::before { content: map-get($bootstrap-icons-map, "check-square-fill"); }
.bi-check-square::before { content: map-get($bootstrap-icons-map, "check-square"); }
.bi-share-fill::before { content: map-get($bootstrap-icons-map, "share-fill"); }
.bi-star-fill::before { content: map-get($bootstrap-icons-map, "star-fill"); }
.bi-star-half::before { content: map-get($bootstrap-icons-map, "star-half"); }
.bi-star::before { content: map-get($bootstrap-icons-map, "star"); }
.bi-check-circle-fill::before { content: map-get($bootstrap-icons-map, "check-circle-fill"); }
.bi-check-circle::before { content: map-get($bootstrap-icons-map, "check-circle"); }
.bi-check-square-minus::before { content: map-get($bootstrap-icons-map, "check-square-minus"); }
.bi-general-clock-fill::before { content: map-get($bootstrap-icons-map, "general-clock-fill"); }
.bi-general-envelope-fill::before { content: map-get($bootstrap-icons-map, "general-envelope-fill"); }
.bi-general-geo-alt-fill::before { content: map-get($bootstrap-icons-map, "general-geo-alt-fill"); }
.bi-general-info-circle-fill::before { content: map-get($bootstrap-icons-map, "general-info-circle-fill"); }
.bi-general-people-fill::before { content: map-get($bootstrap-icons-map, "general-people-fill"); }
.bi-general-telephone-fill::before { content: map-get($bootstrap-icons-map, "general-telephone-fill"); }
.bi-nav-arrow-down::before { content: map-get($bootstrap-icons-map, "nav-arrow-down"); }
.bi-nav-arrow-left::before { content: map-get($bootstrap-icons-map, "nav-arrow-left"); }
.bi-nav-arrow-right::before { content: map-get($bootstrap-icons-map, "nav-arrow-right"); }
.bi-nav-arrow-up::before { content: map-get($bootstrap-icons-map, "nav-arrow-up"); }
.bi-nav-caret-down-fill::before { content: map-get($bootstrap-icons-map, "nav-caret-down-fill"); }
.bi-nav-caret-left-fill::before { content: map-get($bootstrap-icons-map, "nav-caret-left-fill"); }
.bi-nav-caret-right-fill::before { content: map-get($bootstrap-icons-map, "nav-caret-right-fill"); }
.bi-nav-caret-up-fill::before { content: map-get($bootstrap-icons-map, "nav-caret-up-fill"); }
.bi-nav-carret-down-circle-fill::before { content: map-get($bootstrap-icons-map, "nav-carret-down-circle-fill"); }
.bi-nav-fullscreen-exit::before { content: map-get($bootstrap-icons-map, "nav-fullscreen-exit"); }
.bi-nav-fullscreen::before { content: map-get($bootstrap-icons-map, "nav-fullscreen"); }
.bi-nav-grid-fill::before { content: map-get($bootstrap-icons-map, "nav-grid-fill"); }
.bi-nav-list::before { content: map-get($bootstrap-icons-map, "nav-list"); }
.bi-nav-three-dots-vertical::before { content: map-get($bootstrap-icons-map, "nav-three-dots-vertical"); }
.bi-nav-three-dots::before { content: map-get($bootstrap-icons-map, "nav-three-dots"); }
.bi-nav-x-circle-fill::before { content: map-get($bootstrap-icons-map, "nav-x-circle-fill"); }
.bi-nav-x-lg::before { content: map-get($bootstrap-icons-map, "nav-x-lg"); }
.bi-nav-x::before { content: map-get($bootstrap-icons-map, "nav-x"); }
.bi-toggle-check-circle-fill::before { content: map-get($bootstrap-icons-map, "toggle-check-circle-fill"); }
.bi-toggle-check-circle::before { content: map-get($bootstrap-icons-map, "toggle-check-circle"); }
.bi-toggle-check-square-fill::before { content: map-get($bootstrap-icons-map, "toggle-check-square-fill"); }
.bi-toggle-check-square-minus::before { content: map-get($bootstrap-icons-map, "toggle-check-square-minus"); }
.bi-toggle-check-square::before { content: map-get($bootstrap-icons-map, "toggle-check-square"); }
.bi-toggle-share-fill::before { content: map-get($bootstrap-icons-map, "toggle-share-fill"); }
.bi-toggle-star-fill::before { content: map-get($bootstrap-icons-map, "toggle-star-fill"); }
.bi-toggle-star-half::before { content: map-get($bootstrap-icons-map, "toggle-star-half"); }
.bi-toggle-star::before { content: map-get($bootstrap-icons-map, "toggle-star"); }
.bi-weather-cloud-fill::before { content: map-get($bootstrap-icons-map, "weather-cloud-fill"); }
.bi-weather-cloud-rain-fill::before { content: map-get($bootstrap-icons-map, "weather-cloud-rain-fill"); }
.bi-weather-cloud-sun-fill::before { content: map-get($bootstrap-icons-map, "weather-cloud-sun-fill"); }
.bi-weather-lightning-charge-fill::before { content: map-get($bootstrap-icons-map, "weather-lightning-charge-fill"); }
.bi-weather-moon-stars-fill::before { content: map-get($bootstrap-icons-map, "weather-moon-stars-fill"); }
.bi-weather-sun-fill::before { content: map-get($bootstrap-icons-map, "weather-sun-fill"); }
.bi-action-bookmark-fill::before { content: map-get($bootstrap-icons-map, "action-bookmark-fill"); }
.bi-action-bookmark::before { content: map-get($bootstrap-icons-map, "action-bookmark"); }
.bi-action-equal::before { content: map-get($bootstrap-icons-map, "action-equal"); }
.bi-action-gear::before { content: map-get($bootstrap-icons-map, "action-gear"); }
.bi-action-heart::before { content: map-get($bootstrap-icons-map, "action-heart"); }
.bi-action-refresh::before { content: map-get($bootstrap-icons-map, "action-refresh"); }
.bi-action-search::before { content: map-get($bootstrap-icons-map, "action-search"); }
.bi-action-tick-circle-fill::before { content: map-get($bootstrap-icons-map, "action-tick-circle-fill"); }
.bi-action-tick-circle::before { content: map-get($bootstrap-icons-map, "action-tick-circle"); }
.bi-action-tick::before { content: map-get($bootstrap-icons-map, "action-tick"); }
.bi-action-view::before { content: map-get($bootstrap-icons-map, "action-view"); }
.bi-action-wrench::before { content: map-get($bootstrap-icons-map, "action-wrench"); }
.bi-nav-caret-down::before { content: map-get($bootstrap-icons-map, "nav-caret-down"); }
.bi-nav-caret-left::before { content: map-get($bootstrap-icons-map, "nav-caret-left"); }
.bi-nav-caret-right::before { content: map-get($bootstrap-icons-map, "nav-caret-right"); }
.bi-nav-caret-up::before { content: map-get($bootstrap-icons-map, "nav-caret-up"); }
.bi-nav-carret-down-circle::before { content: map-get($bootstrap-icons-map, "nav-carret-down-circle"); }
.bi-nav-grid::before { content: map-get($bootstrap-icons-map, "nav-grid"); }
.bi-toggle-share::before { content: map-get($bootstrap-icons-map, "toggle-share"); }
.bi-alert-bell::before { content: map-get($bootstrap-icons-map, "alert-bell"); }
.bi-alert-info-fill::before { content: map-get($bootstrap-icons-map, "alert-info-fill"); }
.bi-alert-info::before { content: map-get($bootstrap-icons-map, "alert-info"); }
.bi-edit-duplicate::before { content: map-get($bootstrap-icons-map, "edit-duplicate"); }
.bi-edit-edit::before { content: map-get($bootstrap-icons-map, "edit-edit"); }
.bi-edit-link::before { content: map-get($bootstrap-icons-map, "edit-link"); }
.bi-edit-minus::before { content: map-get($bootstrap-icons-map, "edit-minus"); }
.bi-edit-plus::before { content: map-get($bootstrap-icons-map, "edit-plus"); }
.bi-edit-sort::before { content: map-get($bootstrap-icons-map, "edit-sort"); }
.bi-social-fb::before { content: map-get($bootstrap-icons-map, "social-fb"); }
.bi-social-in::before { content: map-get($bootstrap-icons-map, "social-in"); }
.bi-social-tw::before { content: map-get($bootstrap-icons-map, "social-tw"); }
.bi-social-yt::before { content: map-get($bootstrap-icons-map, "social-yt"); }