@import './mixins.scss';
@import './variables.scss';
@import './typography.scss';
@import './colours.scss';
@import './calendar-styles.scss';

@import url("https://use.typekit.net/xrx3zmi.css");

html,
body {
  padding: 0;
  margin: 0;
  font-family: $main-font;
  //font-family: "Helvetica Neue, sans-serif, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans";
  overflow-x: hidden;

}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  cursor: hand;
  &:hover {
    color: $orange;
  }
}

.hover {
  &:hover {
    color: $orange;
  }
}

h1, h2, h3, h4, h5 {
  margin:0;
  font-weight: normal;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

// Scrollbar
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $cream; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey-light; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $grey; 
}

::-webkit-scrollbar-button {

}
