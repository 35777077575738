@import '@styles/variables.scss';
@import '@styles/mixins.scss';

.footer {
    background-color: $brown;
    color: $white;

    font-family: $main-font;
    font-weight: 200;
    font-style: normal;
    font-size: $size_14;

    

    hr {
        border: none;
        height: 1px;
        background-color: $grey;
        width: 290px;
        @include desktop-screen() {
            display: none;
        }
    }

    section {
        margin-bottom: 0;
    }
    
}

.widgetsection {
    @include desktop-screen() {
        text-align: center;
        margin-left: 12px;
    }
    
    display: flex;
    justify-content: center;
    margin: 0 0.5rem;
    padding: 1rem 0;

    .widget {
        margin: 0.3em 1.5em;
        display: flex;
        align-items: center;
        flex-direction: column;
        @include mobile-screen() {
            flex-direction: row;
        }
    }

    .weatherwidget {
        display: flex;
        justify-content: center;
        
    }
    
    .icon {
        font-size: 28px;
        margin-right: 0.2em;
    }

    p {
        margin: 0;
    }

    .timelocationcontainer {
        display: flex;

        @include mobile-screen() {
            flex-direction: column;
        }

        .locationwidget {
            width: 126px;
            @include mobile-screen() {
                width: 160px;
            }
        }

        .timewidget {
            .icontext {
                .time {
                    font-weight: 400;
                }
            }
        }
    }
}


.linksection {
    text-align: center;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @include desktop-screen() {
        background-color: $dark;
    }

    .socials {

        padding-top: 1em;
        padding-bottom: 1em;

        p {
            font-weight: 500;
            margin: 0;
            line-height: 3em;
        }

        a {
            margin: 0 0.3em;
            font-size: 25px;
        }
    }

    .privacy {
        font-weight: 500;
    }    

    p {
        line-height: 0;
        display: block;
    }
}