@import '@styles/mixins.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    p {
        margin: 0;
    }
}

.icon {
    font-size: 28px;
    order: 1;
    
    @include mobile-screen() {
        order: 2;
        margin-top: 6px;
    }
}

.description {
    text-transform: capitalize;
}

.degactive {
    font-weight: 500;
}

.celactive {
    .cel {
        font-weight: 500;
    }
}

.fahactive {
    .fah {
        font-weight: 500;
    }
}

.cel, .fah {
    &:hover {
        color: $orange;
    }
}

.temp {
    cursor: pointer;
    order: 3;
    .tempnum {
        font-weight: 500;
    }
}

.date {
    order: 2;
    @include mobile-screen() {
        order: 1;
    }
}